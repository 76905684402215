// math-csv.js
import { getTaskHandler } from './taskRegistry';

export async function fetchAndStreamCSV(
  taskData,  // Merged task parameters into a single object
  setModelResults,  // Callback function to handle results
  appendLoadingMessage // Renamed for clarity
) {
  try {
    // Log all parameters to ensure they are correct
    console.log('fetchAndStreamCSV called with taskData:');
    console.log('taskData:', taskData);

    // Extract necessary variables from taskData
    const {
      taskType,
      taskMetadata,
      xsBlob,
      ysBlob,
      modelFiles,
      chunkid,
      owner,
      batchid,
      idToken,
    } = taskData;

    console.log('xsBlob:', xsBlob);
    console.log('ysBlob:', ysBlob);
    console.log('modelFiles:', modelFiles);
    console.log('taskType:', taskType);
    console.log('taskMetadata:', taskMetadata);
    console.log('chunkid:', chunkid);
    console.log('owner:', owner);
    console.log('batchid:', batchid);
    console.log('idToken [redacted]');

    // Await the handler since getTaskHandler is an async function
    const handler = await getTaskHandler(taskType);

    if (!handler || typeof handler.streamAndProcess !== 'function') {
      throw new Error(`No valid handler registered for task type: ${taskType}`);
    }

    // Call the streamAndProcess method on the handler, passing the task data
    await handler.streamAndProcess(
      {
        ...taskData,  // Pass all taskData to the handler
        xsBlob,       // Ensure blobs and files are correctly passed
        ysBlob,
        modelFiles
      },
      setModelResults,
      appendLoadingMessage // Pass it here
    );
  } catch (error) {
    console.error("Error in fetchAndStreamCSV:", error);
    throw error;  // Re-throw to be caught in the calling code
  }
}