// App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { getIdToken, getIdentityId, fetchDataFromApi, fetchS3Model, fetchS3Data } from './awsService';
import { fetchAndStreamCSV } from './math-csv'; // Keep this import as is
import '@aws-amplify/ui-react/styles.css';
import './App.css';

function App({ signOut, user }) {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [noTaskFound, setNoTaskFound] = useState(false);
  const [data, setData] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(['Initializing...']); // Initial message
  const [displayResults, setDisplayResults] = useState(null);
  const [isTaskInProgress, setIsTaskInProgress] = useState(false);

  // Ref for the status box to implement auto-scroll
  const statusBoxRef = useRef(null);

  // Ref to track if fetchData is already in progress
  const isFetching = useRef(false);

  // Helper function to append messages with a limit of 100 messages
  const appendLoadingMessage = useCallback((msg) => {
    setLoadingMessages((prev) => {
      const newMessages = [...prev, msg];
      return newMessages.length > 100 ? newMessages.slice(-100) : newMessages; // Keep last 100 messages
    });
  }, []);

  // Helper function to set messages (used to initialize or clear)
  const setLoadingMessageList = useCallback((msgs) => {
    setLoadingMessages(msgs);
  }, []);

  const fetchData = useCallback(async () => {
    if (isTaskInProgress || !shouldFetch) return;

    try {
      setIsTaskInProgress(true);
      appendLoadingMessage('Fetching task...'); // Changed: Append instead of resetting
      setNoTaskFound(false); // Reset noTaskFound
      setData(null);
      setDisplayResults(null);

      console.log('Fetching task...');

      const idToken = await getIdToken();

      // Fetch the identityId using the idToken
      const identityId = await getIdentityId(idToken);

      const result = await fetchDataFromApi(idToken);
      console.log('Data fetched from API:', result);

      // Explicitly log taskType and taskMetadata to avoid warnings
      const taskType = result.taskType;
      const taskMetadata = JSON.parse(result.taskMetadata || '{}');
      console.log('Task Type:', taskType);
      console.log('Task Metadata:', taskMetadata);

      // Check for TensorFlow task
      if (taskType === 'tensorflow' && result.xsS3Location && result.ysS3Location) {
        appendLoadingMessage('TensorFlow task detected.');

        let modelFiles = null; // Declare this earlier for clarity
        if (result.modelS3Location) {
          appendLoadingMessage('Downloading model...');
          console.log(`Downloading model from S3 location: ${result.modelS3Location}`);
          console.log('Fetching model with ID token...');

          modelFiles = await fetchS3Model(result.modelS3Location, idToken); // Fetch both modelJsonBlob and weightsBinBlob
          console.log('Model files fetched from S3:', modelFiles);
          appendLoadingMessage('Model downloaded.');
        }

        setData(result);

        // Fetch the CSV data using AWS SDK
        appendLoadingMessage('Downloading data...');
        console.log(`Downloading data from S3 locations: XS: ${result.xsS3Location}, YS: ${result.ysS3Location}`);

        const xsDataBlob = await fetchS3Data(result.xsS3Location, idToken); // Fetch XS data as Blob
        const ysDataBlob = await fetchS3Data(result.ysS3Location, idToken); // Fetch YS data as Blob
        appendLoadingMessage('Data downloaded.');

        // Update fetchAndStreamCSV call to use Blobs and modelFiles
        console.log('Passing to fetchAndStreamCSV with Blobs and modelFiles');
        appendLoadingMessage('Processing data and training model...');
        await fetchAndStreamCSV(
          {
            ...result,
            taskType,
            taskMetadata,
            xsBlob: xsDataBlob,
            ysBlob: ysDataBlob,
            modelFiles: modelFiles,
            idToken,
            identityId,
            chunkid: result.chunkid, // Ensure correct case
            owner: result.owner,     // Adjust based on actual field names
            batchid: result.batchid, // Ensure correct case
          },
          async (results) => {
            try {
              console.log('Processing completed.');
              appendLoadingMessage('Processing completed.');
            } catch (error) {
              console.error('Error uploading model results:', error);
              appendLoadingMessage('Error uploading results.');
            }

            setDisplayResults(results);
          },
          appendLoadingMessage // Pass appendLoadingMessage to fetchAndStreamCSV
        );

      } else {
        appendLoadingMessage('No task found.');
        console.log('No task found or invalid task type/locations');
        setNoTaskFound(true); // Set noTaskFound to true
        setShouldFetch(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check if the error is a 404
      if (error.message.includes('HTTP error! Status: 404')) {
        appendLoadingMessage('No task found.');
      } else {
        appendLoadingMessage('Error occurred.');
      }
      setNoTaskFound(true); // Set noTaskFound to true
      setShouldFetch(false);
    } finally {
      setIsTaskInProgress(false); // Ensure flag is reset
      isFetching.current = false; // Allow future fetches
    }
  }, [isTaskInProgress, shouldFetch, appendLoadingMessage]);

  useEffect(() => {
    if (shouldFetch && !isTaskInProgress && !isFetching.current) {
      isFetching.current = true; // Set isFetching to true immediately
      fetchData();
    }
  }, [shouldFetch, isTaskInProgress, fetchData]);

  // Auto-scroll to the latest message whenever loadingMessages update
  useEffect(() => {
    if (statusBoxRef.current) {
      statusBoxRef.current.scrollTop = statusBoxRef.current.scrollHeight;
    }
  }, [loadingMessages]);

  const retryFetchData = () => {
    if (!isTaskInProgress) {
      setShouldFetch(true);
      appendLoadingMessage('Retrying task fetch...'); // Changed: Append instead of resetting
      setNoTaskFound(false); // Reset noTaskFound
      fetchData();
    }
  };

  const renderModelResults = (results) => {
    if (!results) return null;

    // Handle TensorFlow completion message
    if (results.message) {
      return (
        <div className="results-container">
          <div>{results.message}</div>
        </div>
      );
    }

    // Original: Handle detailed results for non-TensorFlow tasks
    return (
      <div className="results-container">
        <div>
          <strong>Final Weights:</strong> {results.weights ? results.weights.toString() : ''}
        </div>
        <div>
          <strong>Final Bias:</strong> {results.bias ? results.bias.toString() : ''}
        </div>
      </div>
    );
  };

  return (
    <div className="app-page">
      <div className="app-heading">
        <h2>Riddle: Node</h2>
      </div>
      <div className="app-details">
        <div className="button-group">
          {noTaskFound && !isTaskInProgress && (
            <button className="retry-button" onClick={retryFetchData}>Retry</button>
          )}
          <button className="sign-out-button" onClick={signOut}>Sign out</button>
        </div>
        <div className="status-box" ref={statusBoxRef} aria-live="polite">
          {loadingMessages.map((msg, idx) => (
            <div key={idx}>{msg}</div>
          ))}
        </div>
        <div className="task-details">
          <div>
            <strong>Task Type:</strong> {data ? data.taskType : ''}
          </div>
          <div>
            <strong>Batch ID:</strong> {data ? data.batchid : ''}
          </div>
          <div>
            <strong>Chunk ID:</strong> {data ? data.chunkid : ''}
          </div>
        </div>
        {displayResults && (
          <div>
            <h2>Final Model Results:</h2>
            {renderModelResults(displayResults)}
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(App);