import linearRegressionHandler from './linearRegressionHandler';

// Initialize the task registry
const taskRegistry = {};

// Function to register a task handler
export function registerTaskHandler(taskType, handler) {
    taskRegistry[taskType] = handler;
}

// Register the task handler for linear regression
registerTaskHandler('linear-regression', linearRegressionHandler);

// Lazy-load TensorFlow handler and return dynamically
export async function getTaskHandler(taskType) {
    if (!taskRegistry[taskType]) {
        // Lazy load the appropriate handler based on the task type
        if (taskType === 'tensorflow') {
            const { default: tensorflowHandler } = await import('./tensorflowHandler');
            registerTaskHandler(taskType, tensorflowHandler);
        } else {
            throw new Error(`No handler registered for task type: ${taskType}`);
        }
    }
    return taskRegistry[taskType];
}
