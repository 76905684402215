// awsService.js
import { Amplify } from 'aws-amplify';
import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { fetchAuthSession } from '@aws-amplify/auth';
import { CognitoIdentityClient, GetIdCommand } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const getIdToken = async () => {
  console.log('Fetching ID token...');
  try {
    const session = await fetchAuthSession();
    console.log('Session fetched...');
    
    const idToken = session.tokens.idToken.toString();
    console.log('ID token fetched...');
    return idToken;
  } catch (error) {
    console.error('Error fetching ID token:', error);
    throw error;
  }
};

const getIdentityId = async (idToken) => {
  console.log('Fetching Identity ID with ID token...');
  
  const cognitoIdentityClient = new CognitoIdentityClient({ region: 'us-east-1' });
  const logins = {
    'cognito-idp.us-east-1.amazonaws.com/us-east-1_D0YZWjUpn': idToken,
  };

  const identityParams = {
    IdentityPoolId: 'us-east-1:b56548c0-a6a8-4083-bbe1-9fb1107d8b81',
    Logins: logins,
  };

  console.log('Identity params:', identityParams);

  try {
    const identityData = await cognitoIdentityClient.send(new GetIdCommand(identityParams));
    console.log('Identity data fetched:', identityData);
    
    const identityId = identityData.IdentityId;
    console.log("Identity Pool User ID (Node ID):", identityId);
    return identityId;
  } catch (error) {
    console.error('Error fetching Identity ID:', error);
    throw error;
  }
};

const retryWithBackoff = async (fn, retries = 5, delay = 100) => {
  try {
    console.log(`Retrying function. Retries left: ${retries}, Delay: ${delay}`);
    return await fn();
  } catch (err) {
    console.warn(`Error encountered: ${err.message}. Retrying in ${delay}ms...`);
    if (retries > 0) {
      await new Promise(res => setTimeout(res, delay));
      return retryWithBackoff(fn, retries - 1, delay * 2); // Exponential backoff
    } else {
      console.error('Max retries reached. Throwing error:', err);
      throw err;
    }
  }
};

const fetchDataFromApi = async (idToken) => {
  const headers = {
    Authorization: `Bearer ${idToken}`,
  };
  console.log('Request headers for fetchDataFromApi:', headers);

  const fetchApi = async () => {
    try {
      const response = await fetch('https://q33t7ow89h.execute-api.us-east-1.amazonaws.com/dev/work', {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        console.error('Error response from API:', {
          status: response.status,
          statusText: response.statusText,
          headers: [...response.headers.entries()],
        });
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Data fetched from API:', data);
      return data;
    } catch (error) {
      console.error('Error fetching data from API:', error);
      throw error;
    }
  };

  return retryWithBackoff(fetchApi);
};

const fetchS3Data = async (s3Location, idToken) => {
  console.log('Fetching S3 data from location:', s3Location, 'with ID token...');
  const s3Client = new S3Client({
    region: 'us-east-1',
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: 'us-east-1' }), // <== CHANGE HERE
      identityPoolId: 'us-east-1:b56548c0-a6a8-4083-bbe1-9fb1107d8b81', // Replace with your actual Cognito Identity Pool ID
      logins: {
        'cognito-idp.us-east-1.amazonaws.com/us-east-1_D0YZWjUpn': idToken, // Replace with your Cognito User Pool ID
      },
    }),
  });

  const getObjectParams = {
    Bucket: 'my-data-chunks-bucket',
    Key: s3Location,
  };

  console.log('S3 getObjectParams:', getObjectParams);

  try {
    const command = new GetObjectCommand(getObjectParams);
    const s3Response = await s3Client.send(command);

    console.log('S3 Response:', s3Response);

    const stream = s3Response.Body;
    const blob = await streamToBlob(stream, s3Response.ContentType);
    console.log('Blob fetched from S3:', blob);

    return blob;
  } catch (error) {
    console.error('Error fetching S3 data:', {
      location: s3Location,
      error
    });
    throw error;
  }
};

const fetchS3Model = async (s3Location, idToken) => {
  console.log('Fetching TensorFlow model from S3:', s3Location);

  const s3Client = new S3Client({
    region: 'us-east-1',
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: 'us-east-1' }), // <== CHANGE HERE
      identityPoolId: 'us-east-1:b56548c0-a6a8-4083-bbe1-9fb1107d8b81',
      logins: {
        'cognito-idp.us-east-1.amazonaws.com/us-east-1_D0YZWjUpn': idToken,
      },
    }),
  });

  const modelJsonKey = s3Location;
  const weightsBinKey = s3Location.replace('model.json', 'weights.bin');

  console.log('Fetching modelJson and weightsBin:', { modelJsonKey, weightsBinKey });

  try {
    const modelJsonCommand = new GetObjectCommand({
      Bucket: 'my-data-chunks-bucket',
      Key: modelJsonKey,
    });
    const modelJsonResponse = await s3Client.send(modelJsonCommand);
    console.log('Fetched modelJsonResponse:', modelJsonResponse);

    const modelJsonBlob = await streamToBlob(modelJsonResponse.Body, modelJsonResponse.ContentType);
    console.log('Fetched modelJsonBlob:', modelJsonBlob);

    const weightsBinCommand = new GetObjectCommand({
      Bucket: 'my-data-chunks-bucket',
      Key: weightsBinKey,
    });
    const weightsBinResponse = await s3Client.send(weightsBinCommand);
    console.log('Fetched weightsBinResponse:', weightsBinResponse);

    const weightsBinBlob = await streamToBlob(weightsBinResponse.Body, weightsBinResponse.ContentType);
    console.log('Fetched weightsBinBlob:', weightsBinBlob);

    return {
      modelJsonBlob,
      weightsBinBlob,
    };
  } catch (error) {
    console.error('Error fetching TensorFlow model from S3:', {
      location: s3Location,
      error,
    });
    throw error;
  }
};

// Helper function to read response body to Blob
async function streamToBlob(stream, contentType) {
  const reader = stream.getReader();
  const chunks = [];
  let done, value;

  console.log('Streaming data to Blob, contentType:', contentType);

  while (!done) {
    ({ done, value } = await reader.read());
    if (value) {
      chunks.push(value);
      console.log('Chunk received...');
    }
  }

  console.log('All chunks received. Creating Blob...');
  return new Blob(chunks, { type: contentType });
}

/**
 * Updated sendModelResults function to handle TensorFlow tasks.
 * @param {string} chunkId - The identifier for the task chunk.
 * @param {object|null} results - The results of the task (null for TensorFlow tasks).
 * @param {string} taskType - The type of the task ('tensorflow' or other types).
 * @param {string} idToken - The Cognito ID token for authentication.
 * @param {string} identityId - The Cognito Identity ID of the user.
 * @param {array} modelLocations - The structured model locations.
 * @returns {object} - The response data from the API.
 */
const sendModelResults = async (chunkId, results, taskType, idToken, identityId, modelLocations = []) => {
  const headers = {
    Authorization: `Bearer ${idToken}`,
    'Content-Type': 'application/json',
  };
  console.log('Request headers for sendModelResults:', headers);

  const body = {
    chunkId,
    identityId,
  };

  // Include results only if the task is not TensorFlow
  if (taskType !== 'tensorflow') {
    body.results = results;
  }

  // Include structured modelLocations if provided
  if (modelLocations.length > 0) {
    body.modelLocations = modelLocations;
  }

  console.log('Sending model results:', body);

  try {
    const response = await fetch('https://7krf5254ka.execute-api.us-east-1.amazonaws.com/dev/answer', {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      console.error('Error sending model results:', {
        status: response.status,
        statusText: response.statusText,
        headers: [...response.headers.entries()],
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Model results sent successfully:', responseData);
    return responseData;
  } catch (error) {
    console.error('Error in sendModelResults:', error);
    throw error;
  }
};

const uploadResultsToS3 = async (chunkId, results, idToken, owner, batchId, identityId, fileType = 'json') => {
  console.log('Uploading results to S3...');

  const s3Client = new S3Client({
    region: 'us-east-1',
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: 'us-east-1' }), // <== CHANGE HERE
      identityPoolId: 'us-east-1:b56548c0-a6a8-4083-bbe1-9fb1107d8b81',
      logins: {
        'cognito-idp.us-east-1.amazonaws.com/us-east-1_D0YZWjUpn': idToken,
      },
    }),
  });

  let fileName;
  if (fileType === 'keras') {
    fileName = `${chunkId}.keras`;
  } else if (fileType === 'h5') {
    fileName = `${chunkId}.h5`;
  } else if (fileType === 'bin') {
    fileName = `${chunkId}.bin`; // Use 'weights.bin' as the file name
  } else {
    fileName = `${chunkId}.json`;
  }

  const s3Key = `chunks/${owner}/${batchId}/${chunkId}/${identityId}/${fileName}`;

  const putObjectParams = {
    Bucket: 'my-data-chunks-bucket',
    Key: s3Key,
    Body: fileType === 'json' ? JSON.stringify(results) : results,
    ContentType: fileType === 'json' ? 'application/json' : fileType === 'h5' ? 'application/x-hdf' : fileType === 'bin' ? 'application/octet-stream' : 'application/octet-stream',
    Tagging: `owner=${owner}`,
  };

  console.log('S3 putObjectParams:', putObjectParams);

  try {
    const command = new PutObjectCommand(putObjectParams);
    const s3Response = await s3Client.send(command);
    console.log('Results uploaded to S3:', s3Response);
    // Return the S3 Key along with the response
    return { s3Key, s3Response };
  } catch (error) {
    console.error('Error uploading results to S3:', { chunkId, error });
    throw error;
  }
};

export { getIdToken, getIdentityId, fetchDataFromApi, fetchS3Data, fetchS3Model, sendModelResults, uploadResultsToS3 };
