import { create, all } from 'mathjs';

// Create a math.js instance
const math = create(all);

const linearRegressionHandler = {
  async streamAndProcess(s3Url, metadata, setModelResults) {
    const response = await fetch(s3Url);
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let partialRow = '';
    let headers = null;
    let features = []; // Intermediate array for feature values
    let targets = [];  // Intermediate array for target values

    // Ensure arrays are cleared before processing
    features = [];
    targets = [];

    function processStream({ done, value }) {
      if (done) {
        if (partialRow) {
          processRow(partialRow);
        }
        console.log('Stream complete');
        computeLinearRegression();
        return;
      }

      partialRow += decoder.decode(value, { stream: true });
      let lines = partialRow.split('\n');
      partialRow = lines.pop();

      if (!headers && lines.length > 0) {
        headers = lines.shift().split(',');
      }

      for (const line of lines) {
        processRow(line);
      }

      reader.read().then(processStream);
    }

    function processRow(line) {
      const row = line.split(',');
      const targetIndex = headers.indexOf(metadata.targetVariable);
      const featureValues = row.filter((_, index) => index !== targetIndex).map(parseFloat);
      const targetValue = parseFloat(row[targetIndex]);

      features.push([1, ...featureValues]); // Include a column of 1s for the intercept
      targets.push(targetValue);
    }

    async function computeLinearRegression() {
      const X = math.matrix(features);
      const Y = math.matrix(targets);

      // Calculate (X^T * X)
      const XT = math.transpose(X);
      const XTX = math.multiply(XT, X);

      // Calculate the pseudo-inverse using the inverse of (X^T * X)
      const XTX_inv = math.inv(XTX);

      // Calculate (X^T * Y)
      const XTY = math.multiply(XT, Y);

      // Calculate the coefficients
      const coefficients = math.multiply(XTX_inv, XTY);

      // Extract weights and bias
      const weights = coefficients.toArray().slice(1); // Exclude intercept
      const bias = coefficients.toArray()[0];

      console.log('Weights:', weights);
      console.log('Bias:', bias);

      setModelResults({
        weights: weights,
        bias: bias
      });

      // Clear features and targets after processing
      features = [];
      targets = [];
    }

    reader.read().then(processStream);
  }
};

export default linearRegressionHandler;
